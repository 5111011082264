



















import { Component, Vue } from 'vue-property-decorator';
import { FrownIcon } from 'vue-feather-icons';
import Navbar from '@/components/checkout/layout/Navbar.vue';

@Component({
  components: {
    Navbar,
    FrownIcon
  }
})
export default class UnavailablePage extends Vue {}
